<template>
  <layout page="discipline-list">
    <page-title icon="lni lni-bookmark" :breadcrumb="breadcrumb">Disciplinas</page-title>
    <div class="card table">
      <header class="card-header">
        <p class="card-header-title">
          <span>Disciplinas</span>
        </p>
        <div class="card-header-icon" aria-label="nova disciplina">
          <router-link class="button is-primary" to="/admin/disciplinas/nova">
            <i class="lni lni-plus mr-1"></i> <span>Nova Disciplina</span>
          </router-link>
        </div>
      </header>
      <div class="card-content">
        <div v-if="disciplines.length > 0" class="table-container">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>Nome</th>
                <td class="has-text-right">Ações</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(discipline, index) in disciplines" :key="discipline.id">
                <td>{{ discipline.name }}</td>
                <td class="has-text-right">
                  <router-link class="button is-primary is-small mr-1" :to="`/admin/disciplinas/${discipline.id}/editar`" title="Editar Disciplina">
                    <i class="lni lni-pencil-alt"></i>
                  </router-link>
                  <button class="button is-danger is-small" title="Excluir Disciplina" @click="excluir(index, discipline.id, discipline.name)">
                    <i class="lni lni-trash"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <empty-list v-else entity="disciplina" />
      </div>
    </div>
  </layout>
</template>

<script>
import api from '@/api'
import Swal from 'sweetalert2'
import { DASHBOARD, DISCIPLINE_LIST } from '@/config/breadcrumb/admin'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'
import EmptyList from '@/components/ui/EmptyList'

export default {
  name: 'DisciplineList',
  components: {
    Layout,
    PageTitle,
    EmptyList
  },
  created () {
    api.get('/disciplines').then(res => {
      this.disciplines = res.data
    })
  },
  data () {
    return {
      disciplines: []
    }
  },
  computed: {
    breadcrumb () {
      return [DASHBOARD, { ...DISCIPLINE_LIST, isActive: true }]
    }
  },
  methods: {
    excluir (index, id, name) {
      Swal.fire({
        title: 'Tem certeza?',
        text: `Você tem certeza que deseja excluir a disciplina: ${name}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar'
      }).then(result => {
        if (result.isConfirmed) {
          api.delete(`/disciplines/${id}`).then(res => {
            if (res.status === 200) {
              this.disciplines.splice(index, 1)
            }
          })
        }
      })
    }
  }
}
</script>
